<template>
    <div class="test-api-redoc">
        <!-- <redoc spec-url="/docs/api/customer.json"</redoc> -->
        <!-- <redoc spec-url="/docs/api/serviceprovider.json"></redoc> -->
        <redoc spec-url="/docs/api/test-pets.yaml"></redoc>
    </div>
</template>

<script>
export default {
    mounted() {
        let script = document.createElement('script')
            script.src = 'https://cdn.jsdelivr.net/npm/redoc@next/bundles/redoc.standalone.js'
            script.onreadystatechange = script.onload = () => {
                if (!script.readyState || /loaded|complete/.test(script.readyState)) {
                    console.log('redoc.standalone.js loaded...')
                }
            }

        document.getElementsByTagName('head')[0].appendChild(script)
    },
}
</script>

<style lang="scss">
.test-api-redoc {
    padding: 80px 0 360px;

    .row {
        &>div {
            margin-bottom: 24px;

            &>* {
                margin-bottom: 24px;

                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}
</style>